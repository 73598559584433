<template>
  <RadioBox
    v-for="(color, index) in colors"
    :key="index"
    name="personal"
    :id="color.color"
    v-model="color.code"
    :color="color.color"
    @click="handleColor(index)"
    :checked="selectColor == color.code"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import RadioBox from './RadioBox.vue';
import { PROFILE_COLOR } from '@/shared/constans/profile';

defineProps({
  selectColor: {
    type: String,
    default: '#00D696',
  },
});

const colors = ref(PROFILE_COLOR);
const emit = defineEmits(['update:selectColor']);
const handleColor = (index: number) => {
  emit('update:selectColor', colors.value[index].code);
};
</script>

<style lang="scss" scoped>
.join-page {
  &__color-radio {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }
}
</style>
