<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      :class="`${classPopup} c-popup`"
    >
      <div
        type="button"
        class="c-popup__dim"
        @click="closePopup"
      />
      <div
        :class="`c-popup__inner ${
          isFullScreen ? 'max-h-[100%] h-[100%] !min-w-[100%] !max-w-[100%]' : ''
        }`"
        :style="stylePopup"
        ref="myDivRef"
      >
        <div
          v-if="showHeader"
          :class="`c-popup__header ${canDrag ? 'cursor-grab' : ''}`"
          @mousedown="dragMouseDown"
        >
          <span class="c-popup__title">
            {{ title }}
          </span>
          <div class="flex items-center gap-[28px]">
            <button
              v-if="canZoom"
              type="button"
              @click="
                () => {
                  stylePopup = { ...defaultStylePopup };
                  isFullScreen = !isFullScreen;
                  $emit('zoomIn', isFullScreen);
                }
              "
            >
              <i :class="`c-icon c-icon--${isFullScreen ? 'zoom-out' : 'zoom-in'}`">
                <span class="s-hide">expand</span>
              </i>
            </button>
            <button
              type="button"
              @click="closePopup"
            >
              <i class="c-icon c-icon--close">
                <span class="s-hide">close</span>
              </i>
            </button>
          </div>
        </div>
        <div
          :class="`c-popup__body ${nonScroll ? 'c-popup__body--none-scroll' : ''} ${
            isFullScreen ? 'max-h-[100%]' : ''
          }`"
        >
          <slot />
        </div>
        <div
          v-if="showFooter"
          class="c-popup__footer"
        >
          <slot name="footer">
            <BaseButton
              color="outline"
              size="small"
              @click="closePopup"
              >{{ closeText }}</BaseButton
            >
            <BaseButton
              size="small"
              @click="confirm"
              :disabled="confirmButtonDisabled"
              >{{ confirmText }}</BaseButton
            >
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import { $t } from '@/plugins/translation';

const props = defineProps({
  confirmButtonDisabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'title',
  },
  confirmText: {
    type: String,
    default: () => $t('common', 'confirm'),
  },
  closeText: {
    type: String,
    default: () => $t('common', 'cancel'),
  },
  classPopup: {
    type: String,
    default: '',
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  nonScroll: {
    type: Boolean,
    default: false,
  },
  onClose: Function,
  onConfirm: Function,
  canDrag: {
    type: Boolean,
    default: false,
  },
  canZoom: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue', 'confirm', 'zoomIn']);
const closePopup = () => {
  emit('update:modelValue', false);
  if (props.onClose) {
    props.onClose();
  }
};
const confirm = () => {
  emit('update:modelValue', false);
  if (props.onConfirm) {
    props.onConfirm();
  } else emit('confirm');
};

const isFullScreen = ref(false);

const myDivRef = ref(null);
const defaultStylePopup = {
  position: 'absolute',
  top: null,
  left: null,
};
const stylePopup = ref({ ...defaultStylePopup });

let pos1 = 0,
  pos2 = 0,
  pos3 = 0,
  pos4 = 0;

const dragMouseDown = (e) => {
  if (!props.canDrag) return;
  e.preventDefault();
  pos3 = e.clientX;
  pos4 = e.clientY;

  document.addEventListener('mousemove', elementDrag);
  document.addEventListener('mouseup', closeDragElement);
};

const elementDrag = (e) => {
  e.preventDefault();
  pos1 = pos3 - e.clientX;
  pos2 = pos4 - e.clientY;
  pos3 = e.clientX;
  pos4 = e.clientY;

  stylePopup.value.top = `${myDivRef.value.offsetTop - pos2}px`;
  stylePopup.value.left = `${myDivRef.value.offsetLeft - pos1}px`;
};

const closeDragElement = () => {
  document.removeEventListener('mouseup', closeDragElement);
  document.removeEventListener('mousemove', elementDrag);
};
</script>

<style lang="scss">
.c-popup {
  $this: '.c-popup';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &.full-popup {
    .c-popup__inner {
      min-width: 80%;
      max-width: 80%;
    }
  }

  &.link-popup {
    .c-popup__inner {
      min-width: 60rem;
    }
  }

  &.history-popup {
    .c-popup__inner {
      min-width: 70%;
      max-width: 70%;
    }
  }

  &.profile-popup {
    .c-popup__inner {
      min-width: 620px;
      max-width: 620px;
    }
    .c-popup__body {
      border-radius: 10px;
    }
  }

  &__dim {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    min-width: 60rem;
    max-width: 80rem;
    max-height: 80vh;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    z-index: 20;
    display: flex;
    flex-flow: column;
  }

  &__header {
    background-color: var(--gray-50);
    padding: 1.8rem 2.4rem 1.8rem 2.4rem;
    border-radius: 0.8rem 0.8rem 0 0;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: var(--font-18);
    color: var(--main-10);
    font-weight: 500;
  }

  &__body {
    position: relative;
    background-color: var(--white);
    // overflow-y: visible;
    height: 100%;
    max-height: 67vh;
    overflow-y: auto;

    &--none-scroll {
      overflow-y: unset;
    }
  }

  &__wrap {
    padding: 4rem;
    gap: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & .c-form {
      width: 32rem;
    }
  }

  &__label {
    width: 10rem;
    color: var(--label);
    font-size: var(--font-14);
    font-weight: var(--font-medium);
    line-height: 2.2rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 0;
    gap: 1.2rem;
    border-radius: 0 0 0.8rem 0.8rem;
    border-top: 1px solid #d9d9d9;
    background-color: var(--gray-50);
    position: relative;
  }
}

// tobe animation
</style>
