import moment from 'moment';
import { MilestoneHistoryType, StoryHistoryType, CommonHistoryType } from '@/api/history';
import { partial } from 'lodash';
export const EN = {
  metadata: {
    title: 'EPOSO: The New Standard in IT Project Management',
    description:
      'Enhance IT project transparency, improve schedule adherence, and manage issues effectively with EPOSO. Increase project success rates through real-time monitoring, intuitive schedule management, and systematic issue tracking. Operate IT projects more easily and quickly with EPOSO.',
    keywords:
      'IT project management, project monitoring, schedule management, issue tracking, team collaboration, project dashboard, software development management, project visibility, project delay prevention, developer resource management, IT outsourcing',
  },
  common: {
    confirm: 'Confirm',
    client: 'Client',
    professional: 'Professional',
    delete: 'Delete',
    link: 'Link',
    edit: 'Edit',
    request: 'Request',
    month: '', // no text in English
    save: 'Save',
    cancel: 'Cancel',
    add: 'Add',
    textLengthLimit: (length: number) => `Text within ${length} characters`,
    role: {
      manager: 'Manager',
      professional: 'Professional',
      client: 'Client',
    },
    webhook: {
      slack: 'Slack',
      teams: 'Teams',
      dooray: 'Dooray',
      discord: 'Discord',
    },
    project: 'Project',
    epic: 'Epic',
    justAfter: 'Just now',
    minLater: (n: number) => `${n} minutes ago`,
    hourLater: (n: number) => `${n} hours ago`,
    today: 'Today',
    none: 'None',
    history: 'History',
    unassigned: 'Unassigned',
    showMore: 'Show more',
    showLess: 'Show less',
    startDate: 'Start date',
    endDate: 'End date',
    formatDt: (date: any, initFormat: string, customFormat: string) =>
      initFormat
        ? moment
            .utc(date, initFormat)
            .local()
            .format(customFormat ? customFormat : 'MMM. d, yyyy')
        : moment(date).format(customFormat ? customFormat : 'MMM. d, yyyy'),
    deleteNoti:
      'All content will be deleted and cannot be recovered.Are you sure you want to delete it?',
    home: 'Home',
    story: 'Story',
    milestone: 'Milestone',
    title: 'Title',
    noData: 'There is no data.',
    setting: 'Setting',
    status: 'Status',
    manager: 'Manager',
    reporter: 'Reporter',
    detail: 'Detail',
    send: 'Send',
    reject: 'Reject',
    sign: 'Sign',
    keyword: 'Keyword',
  },
  component: {
    join: {
      emailAuth: 'Email Authentication',
      emailAuthMsg: `Your email will be authenticated when you click "Authenticate".`,
      auth: 'Authenticate',
      authComment: [
        'If authentication does not proceed normally,',
        'please copy the link below and paste it into your internet browser.',
      ],
    },
    header: {
      convertClient: 'Switched to Client',
      convertProfessional: 'Switched to Professional',
      noLoggedIn: 'Sign up and try our service',
      loginJoin: 'Login/Sign Up',
      dashboard: 'Dashboard',
      timeline: 'Timeline',
      settlement: 'Settlement',
      settings: 'Project Settings',
      profile: 'Profile',
      logout: 'Logout',
      addLink: 'Add Link',
    },
    noProject: {
      title: 'No Projects',
      text: 'Join a project or create a new one.',
      button: 'Go to Home',
    },
    taskEditor: {
      thread: 'Thread',
      comment: 'Comment',
      enterComment: 'Please enter your comment.',
      record: 'Record',
      enterYourStory: 'Enter your story.',
      period: 'Period',
      progressStatus: 'Progress status',
      manager: 'Manager',
      reporter: 'Reporter',
      approvalRequest: 'Approval request',
      approve: 'Approve',
      reject: 'Reject',
      aiTooltip:
        'When you enter a title and click Generate AI, it automatically writes a story that matches the title.',
      createSentence: 'Creating a sentence. Please wait a moment.',
      storyPoint: 'Story Point',
    },
    noComment: {
      text: 'Try writing a thread.',
      title: 'No threads have been created.',
    },
    taskComment: {
      confirmDelete: 'Would you like to delete this thread?',
      confirmCloseComment: 'There is something being edited. Are you sure you want to leave?',
    },
    historyList: {
      getTitleChange: (
        type: MilestoneHistoryType | StoryHistoryType | CommonHistoryType,
        typeTask: 'milestone' | 'story',
      ): string => {
        const storyText = {
          [CommonHistoryType.CREATE]: 'Added the story.',
          [CommonHistoryType.CHANGE_TITLE]: 'Changed the title.',
          [CommonHistoryType.CHANGE_DESCRIPTION]: 'Changed the description.',
          [CommonHistoryType.CHANGE_APPROVER_ASSIGNEE]: 'Changed the assignee.',
          [CommonHistoryType.CHANGE_REPORTER]: 'Changed the reporter.',
          [CommonHistoryType.CHANGE_LINK]: 'Changed the link.',
          [CommonHistoryType.CHANGE_SCHEDULE]: 'Changed the period.',
          [CommonHistoryType.CHANGE_STATUS]: 'Changed the story status.',
          [CommonHistoryType.AUTO_CHANGE_STATUS]: 'Changed automatically, there is no changer.',
          [StoryHistoryType.CHANGE_EPIC]: 'Changed the epic.',
          [CommonHistoryType.CREATE_COMMENT]: 'Entered a comment.',
        };
        const milestoneText = {
          [CommonHistoryType.CREATE]: 'Added the milestone.',
          [CommonHistoryType.CHANGE_TITLE]: 'Changed the title.',
          [CommonHistoryType.CHANGE_DESCRIPTION]: 'Changed the description.',
          [CommonHistoryType.CHANGE_APPROVER_ASSIGNEE]: 'Changed the assignee.',
          [CommonHistoryType.CHANGE_REPORTER]: 'Changed the reporter.',
          [CommonHistoryType.CHANGE_LINK]: 'Changed the link.',
          [CommonHistoryType.CHANGE_SCHEDULE]: 'Changed the period.',
          [CommonHistoryType.CHANGE_STATUS]: 'Changed the milestone status.',
          [CommonHistoryType.AUTO_CHANGE_STATUS]: 'Changed automatically, there is no changer.',
          [MilestoneHistoryType.REQUEST_APPROVED]: 'Approval has been requested.',
          [MilestoneHistoryType.APPROVED]: 'Approval has been approved.',
          [MilestoneHistoryType.REJECT]: 'Approval has been rejected',
          [CommonHistoryType.CREATE_COMMENT]: 'Entered a comment.',
        };
        return typeTask === 'milestone'
          ? milestoneText[type as MilestoneHistoryType | CommonHistoryType]
          : storyText[type as StoryHistoryType | CommonHistoryType];
      },
    },
    addRequest: {
      makeRequest: 'Make a Request',
      requestPlaceholder: 'I would like to request a task',
    },
    addContract: {
      makeContract: 'Make a contract',
      recipient: 'Recipient',
      sender: 'Sender',
    },
    timelineFilter: {
      milestoneStatus: 'Milestone Status',
      storyStatus: 'Story Status',
      resetFilter: 'Reset filter',
      keywordPlaceholder: 'Story search',
    },
    workingHour: {
      setWorkingHour: 'Set Working Hours',
      workingHour: 'Working Hours',
      commuteTime: 'Commute Time',
      applyPeriod: 'Bulk Apply Period',
    },
    warningSubscription: {
      warning:
        'There are not enough project sheets. Please increase the subscription number or delete the person in charge within 3 days. The project will no longer be available after this.',
    },
  },
  views: {
    home: {
      slideItem: [
        {
          title: `Work by <span>choosing your time</span> freely.`,
          subtext: `Freelancers can set their working hours in stories.<br>Get paid for the time you work.`,
        },
        {
          title: `Unsure <span>where to outsource</span> your project?`,
          subtext: `Post a development notice on Eposo.<br>Check applicant profiles and make contracts.`,
        },
        {
          title: `Is the <span>developed content</span> different from what you expected?`,
          subtext: `Reduce development risks through communication between clients and professionals.<br>On Eposo, clients and professionals consult and proceed with development in stages.`,
        },
      ],
      slogan: 'Innovation in IT Project Management',
      landingWrap: [
        'EPOSO solves the lack of transparency, schedule delays, and issue management difficulties in IT project management.',
        'EPOSO sets the standard for real-time IT project management.',
      ],
      go: 'Create a Free Project',
      point: 'Point',
      onPrepared: 'We are preparing for launch',
      onPreparedShort: 'In Preparation',
      pointText: [
        {
          title: 'Systematic Issue Tracking',
          text: [
            'Immediately register and track problems that occur during project progress.',
            'Reduce project risks by transparently managing the issue resolution process.',
          ],
          comment: 'You can easily identify delayed issues.',
        },
        {
          title: 'Efficient Schedule Management',
          text: [
            'Systematically manage complex schedules with an intuitive interface.',
            'Timeline visualization makes schedule management even easier.',
          ],
          comment: 'Timeline visualization makes schedule management even easier.',
        },
        {
          title: 'Transparent Project Management',
          text: [
            'Grasp the entire project at a glance with real-time dashboards.',
            'Clearly see progress and enable quick decision-making.',
          ],
          comment: 'Reduce mutual concerns by getting client approval before starting work.',
        },
        {
          title: 'Accurate Workforce Management',
          text: [
            'Effectively manage workforce allocation and participation rates.',
            'Increase accuracy and efficiency in project settlements.',
          ],
          comment: 'Easily settle payments for the time worked on the project.',
        },
        {
          title: 'Global Talent Connection',
          text: [
            'Connect projects with excellent talents from various regions.',
            'Easily find the most suitable talent for your project.',
          ],
          comment: 'Post projects that need development and recruit applicants.',
        },
        {
          title: 'AI Project Auto-Generation',
          text: [
            'Automatically generate projects with just a few questions.',
            'Start development right away with AI-suggested feature lists.',
          ],
          comment: 'AI makes project creation easy for you.',
        },
      ],
      pointImg: [
        require('@/assets/images/main/randing1_en.png'),
        require('@/assets/images/main/randing2_en.png'),
        require('@/assets/images/main/randing3_en.png'),
        require('@/assets/images/main/randing4_en.png'),
        require('@/assets/images/main/randing5_en.png'),
        require('@/assets/images/main/randing6_en.png'),
        require('@/assets/images/main/randing7.png'),
      ],
      tail: [
        'Increase project visibility and maximize management efficiency with EPOSO.',
        'Start more systematic project management with EPOSO.',
      ],
    },
    login: {
      title: ['Sign up easily with SNS', 'and use our service'],
      googleLogin: 'Google Login / Easy Sign Up',
      emailLogin: 'Email Login',
      emailId: 'Email ID',
      password: 'Password',
      join: 'Sign Up',
      find: 'Find Account',
      error: [
        'The password is incorrect.',
        'No matching account information found.',
        'Account locked due to 5 or more incorrect entries. Please reset your password.',
        'Login failed. Please try again.',
      ],
      toast: [
        '',
        'Email has been sent.',
        'Invalid access.',
        'Password has been changed.',
        '',
        'Email authenticated.',
        'Email authentication failed.',
        'Account has been deleted.',
        'Please try email login.',
      ],
    },
    dashboard: {
      process: 'Progress / Plan',
      milestone: 'Milestone',
      timeCost: 'Time Spent',
      request: 'Requests',
      procRate: 'Progress Rate',
      approvalRequest: 'Request',
      status: 'Status',
      approvalDate: 'Approval',
      approval: 'Approve',
      withdrawal: 'Reject',
      approved: 'Approved',
      noApprovalRequest: {
        title: 'No approval requests.',
        text: 'You can write milestones and get approval from the client.',
      },
      milestoneProc: 'Milestone Progress',
      noMilestones: {
        title: 'No milestones.',
        text: 'The progress period of milestones will be displayed.',
      },
      myRequests: 'My Requested Services',
      assignee: 'Assignee',
      requestDate: 'Request',
      completeDate: 'Completion',
      noRequests: {
        title: 'No requested services.',
        text: 'Try requesting tasks from project-related personnel.',
      },
      myContract: 'My Contract Status',
      contract: 'Contract',
      index: 'Category',
      target: 'Target',
      sendDate: 'Send Date',
      contractDate: 'Contract Date',
      send: 'Send',
      receive: 'Receive',
      noContract: {
        title: 'No ongoing contracts.',
        text: 'Try initiating contracts between project-related personnel.',
      },
    },
    timeline: {
      status: 'Status',
      startDate: 'Start Date',
      endDate: 'End Date',
      addMilestone: 'Add Milestone',
      waitingStories: 'Backlog',
      addStory: 'Add Story',
    },
    setting: {
      addProject: 'Add Project',
      projectPeriod: 'Project Period',
      startDate: 'Start Date',
      endDate: 'End Date',
      startDateShorter: 'Start',
      endDateShorter: 'End',
      dateError: 'The later date cannot be earlier than the previous date.',
      addAsMember: 'Add by Member Email',
      member: 'Member',
      email: 'Email',
      company: 'Company',
      role: 'Role',
      assign: 'Assign',
      auth: 'Authority',
      unfold: 'Expand',
      epic: 'Epic',
      weight: 'Weight',
      addEpic: 'Add Epic',
      deleteProject: 'Delete Project',
      askDelete: [
        'Are you sure you want to delete?',
        'All project content will be deleted and cannot be recovered!',
      ],
      notValidEmail: 'Not a valid email format.',
      successAddMember: 'Successfully added member.',
      epicLimit: 'The number of epics is limited to 30 or fewer.',
      deleteAsk: 'Are you sure you want to delete?',
      cardRegist: 'Card registration successful.',
      cardRegistFail: 'Card registration failed.',

      addWebhook: 'Add Webhook',
      webhookLink: 'Webhook Link URL',
      successAddWebhook: 'Successfully added webhook.',
      notUrlEmail: 'Not a valid url format.',
      webhookLabel: {
        type: 'Type',
        url: 'URL',
        milestone: 'Milestone',
        story: 'Story',
        request: 'Request',
        write: 'New',
        update: 'Edit',
        delete: 'Delete',
      },

      couponRegist: 'Coupon registration successful.',
      couponRegistFail: 'Coupon registration failed.',
      couponExpiredOrNone: 'The coupon has expired or does not exist.',
      couponIsMontly: 'This is a monthly payment coupon.',
      couponIsYearly: 'This is a yearly payment coupon.',
      couponAlreadyRegisterd: 'The coupon has already been registered.',
      subscribe: 'Subscription successful.',
      subscribeFail: 'Subscription failed.',
      refund: 'Refund successful.',
      refundFail: 'Refund failed.',
      subscribeAdd: 'Subscription added successfully.',
      subscribeAddFail: 'Subscription addition failed.',
      enterCoupon: 'Please enter a coupon number.',
      usedSheets: 'Used Sheets', // 사용 시트
      sheets: 'Sheets', // 시트
      sheet: 'Sheet', // 시트
      free: 'Free', // 무료
      paid: 'Paid', // 유료
      subscription: 'Subscription', // 구독
      subscriptionChange: 'Change Subscription', // 구독 변경
      paymentManagement: 'Payment Management', // 결제 관리
      registerSubscription: 'Register Subscription', // 구독 등록
      paymentInfo: 'Payment Information', // 결제 정보
      usingForFree: 'Currently using for free', // 현재 무료로 사용 중
      usingMonthly: 'Monthly Plan', // 현재 월 요금제 사용 중
      usingYearly: 'Yearly Plan', // 현재 연 요금제 사용 중
      subscriptionInfo: 'Subscription Info', // 구독정보
      paymentCard: 'Payment Card', // 결제 카드
      coupon: 'Coupon', // 쿠폰
      useCoupon: 'Use Coupon', // 쿠폰 사용
      couponInUse: 'In Use', // 사용 중인 쿠폰
      registerCoupon: 'Register Coupon', // 쿠폰 등록
      couponNumber: 'Coupon No.', // 쿠폰 번호
      registerWord: 'Register', // 등록
      availableCoupon: 'Available Coupons', // 사용 가능 쿠폰
      choose: 'Choose', // 선택
      selectCoupon: 'Select Coupon', // 쿠폰 선택
      nextPaymentDate: 'Next Payment Date', // 다음 결제일
      paymentHistory: 'Payment History', // 결제 내역
      registerCard: 'Register Card', // 카드 등록
      notRegistered: 'Not registered', // 등록된 카드가 없습니다

      agreeToTerms: 'I agree to the terms and conditions', // 약관에 동의합니다
      agreeToPayment: 'I agree to proceed with the payment.', // I agree to the payment
      noRecordForPayment: 'No payment record.', // 결제 내역이 없습니다
      cardNumber: 'Card Number', // 카드번호
      expirationDate: 'Expiration Date', // 유효기간
      birthDate: 'Date of Birth', // 생년월일
      businessRegNum: 'Business registration number',
      businessRegNumPh: "enter without '-'", // Business registration number
      corporateCard: 'Corp. Card', // 법인카드
      ownerName: 'Owner Name', // 소유주명
      password: 'Password', // 비밀번호

      partial: 'Partial', // 부분
      full: 'Full', // 전체
      cancel: 'Cancel', // 취소
      save: 'Save', // 저장
      subscribeWord: 'Subscribe', // Subscribe
      monthWord: 'Month', // Month
      yearWord: 'Year', // Year

      subscriptionSheets: 'Sheets No.', // 구독 시트
      paymentMethod: 'Pay Method', // 결제 방법
      monthlyPlanPrice: 'Monthly Plan', // 월 요금제
      yearlyPlanPrice: 'Yearly Plan', // 연 요금제
      monthlySubscription: 'Monthly Subscription', // 월 구독
      yearlySubscription: 'Yearly Subscription', // 연 구독
      subscriptionPeriod: 'Period', // 구독 기간
      paymentAmount: 'Price', // 결제 금액
      additionalWord: 'Additional', // 추가
      addedCost: 'Added cost', // 추가 금액
      refundHistory: 'Refund History', // 환불 내역
      refundWord: 'Refund', // 환불
      amountWord: 'Amount', // 금액
      payWord: 'Pay', // 결제
      priceWord: 'Price', // 금액
      basicDiscountWord: 'basic discount', // 할인
      couponDiscountWord: 'discount', // 쿠폰 할인
      totalRefundAmount: 'Total Amount', // 총 환불 금액
      termLanguage: 'EN',
      currency: 'KRW',
      sheetsAvailable: 'sheets available',
      total: 'Total of',
      day: 'day',
      days: 'days',
      use: 'Use',
      used: 'Used',
      times: 'times',
    },

    profile: {
      editPhoto: 'Edit Photo',
      nickname: 'Nickname',
      personalColor: 'Personal Color',
      email: 'Email',
      authEmail: 'Authenticate Email',
      name: 'Name',
      phone: 'Phone Number',
      numberOnly: 'Please enter numbers only.',
      birth: 'Date of Birth',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      task: 'Task',
      job: 'Job',
      taskType: 'Task Type',
      freelancer: 'Freelancer',
      officer: 'Full-time Employee',
      hopeLaborDay: 'Desired Daily Rate',
      pricePerDay: 'thousand won / 8 hours',
      company: 'Company',
      career: 'Career',
      deleteCareer: 'Delete Career',
      companyName: 'Company Name',
      workPeriod: 'Work Period',
      dateError: 'End date cannot be earlier than start date.',
      level: 'Position/Job',
      salaryYear: 'Annual Salary (thousand won)',
      addCareer: 'Add Career',
      setAccount: 'Account Settings',
      addProfile: 'Register Profile',
      account: 'Account',
      withdrawAccount: 'Delete Account',
      resetPassword: 'Reset Password',
      agreeMarketing: 'Marketing Consent',
      agreedAt: 'Consent Date',
      notification: 'Notifications',
      notiAll: 'All Notifications',
      notiAllMsg: 'Receive all notification messages.',
      notiEmail: 'Email Notifications',
      notiEmailMsg: 'Receive notifications to the set email.',
      notiNewTask: 'New Task Notifications',
      notiNewTaskMsg: 'Receive notifications when assigned as the person in charge.',
      notiDelayed: 'Delay Notifications',
      notiDelayedMsg: 'Receive notifications when there are delayed stories.',
      notiReq: 'Task Request Notifications',
      notiReqMsg: 'Receive notifications when there are new requests for you.',
      withdrawAsk: (nickname: string) => `${nickname}, are you sure you want to withdraw?`,
      withdrawMsg: [
        'If you withdraw now, the client may claim damages if problems occur',
        'with ongoing or scheduled work.',
        'If you withdraw now, all data in your current account will be lost.',
        'Even if you sign up again with the same account later, the data will not be recovered.',
      ],
      giveFeedback: 'We would appreciate your feedback!',
      feedbackPlaceholder:
        'Could you tell us why you are leaving the service?&#10;Your feedback on any disappointing aspects will be of great help in improving our service.',
      withdrawCheckbox: 'I have read and agree to the account deletion precautions.',
      resetPasswordSent: 'A password reset email has been sent. Please check your email.',
      success: 'Profile registration successful.',
      askDelete: 'Are you sure you want to delete?',
      careerFull: 'You cannot add any more career entries.',
    },
    taskEditor: {
      thread: 'Thread',
      comment: 'Comments',
      commentPlaceholder: 'Enter comments.',
    },
    epic: {
      epic: 'Epic',
      epicLabel: 'Epic Label',
      point: 'Point',
      weight: 'Weight',
      progress: 'Progress',
      progressGap: 'Progress Gap',
    },
  },
  status: {
    timeline: {
      completed: 'Completed',
      inProgress: 'In Progress',
      pending: 'Pending',
      delayed: 'Delayed',
      considering: 'In review',
      rejected: 'Rejected',
      approved: 'Approved',
      approving: 'Approving',
    },
    request: {
      awaiting: 'Awaiting',
      inProgress: 'In Progress',
      completed: 'Completed',
    },
    contract: {
      completed: 'Completed',
      inProgress: 'In Progress',
      rejected: 'Rejected',
    },
  },
};
